import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <>
    <h1>Hello Worlds</h1>
    </>
  );
}

export default App;
